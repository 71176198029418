<template>
  <v-container
    id="admin-version-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-git"
    >
      <template #title>
      {{ $t('layout.sidebar.version') }}
      </template>
      <header-form />
      <table-version />
    </material-card>
    <dialog-version-form-update />
  </v-container>
</template>

<script>
  import HeaderForm from '../modules/admin/version/components/HeaderForm'
  import TableVersion from '../modules/admin/version/components/TableVersion'
  import DialogVersionFormUpdate from '../modules/admin/version/components/DialogVersionFormUpdate'

  export default {
    name: 'AdminVersion',
    components: {
      HeaderForm,
      TableVersion,
      DialogVersionFormUpdate,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Version',
    },
  }
</script>
