<template>
  <v-dialog
    v-if="isShowDialogVersionForm"
    v-model="isShowDialogVersionForm"
    persistent
    max-width="90%"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ dataForm.id ? "Edit" : "Add" }} Version</span
          >
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dataForm.title"
            :rules="titleRules"
            label="Title"
            outlined
            dense
          />
          <div>
            <!-- <label>Date Updated</label> -->
            <v-menu
              v-model="menuInputDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataForm.dateUpdated"
                  label="Date Updated (read only text field)"
                  hint="YYYY/MM/DD format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dataForm.dateUpdated"
                no-title
                @input="menuInputDate = false"
              />
            </v-menu>
          </div>
          <div class="mt-5">
            <label
              >Description
              <span
                style="cursor: pointer; font-weight: bold"
                @click="showRawHTML = !showRawHTML"
              >
                (View {{ showRawHTML ? "" : "Raw" }} HTML)
              </span>
            </label>
            <div class="mt-2">
              <v-textarea
                v-if="showRawHTML"
                v-model="dataForm.description"
                outlined
                dense
                placeholder="Description"
                :rows="10"
              />
              <tiptap-vuetify
                v-else
                v-model="dataForm.description"
                :extensions="extensions"
                placeholder="Description"
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey"
            @click="isShowDialogVersionForm = false"
            v-text="$t('user.userForm.actions.close')"
          />
          <v-btn
            color="info"
            :disabled="!valid"
            @click="save"
            v-text="$t('user.userForm.actions.save')"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync } from "vuex-pathify";
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "tiptap-vuetify";
export default {
  name: "DialogVersionFormUpdate",
  components: {
    TiptapVuetify,
  },
  data: () => ({
    valid: true,
    titleRules: [
      (v) => !!v || "Title is required",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    menuInputDate: false,
    showRawHTML: false,
    extensions: [
      History,
      Table,
      TableCell,
      TableHeader,
      TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem, // if you need to use a list (BulletList, OrderedList)
      BulletList,
      OrderedList,
      Image,
      [
        Heading,
        {
          // Options that fall into the tiptap's extension
          options: {
            levels: [1, 2, 3, 4, 5, 6, 7],
          },
        },
      ],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak, // line break on Shift + Ctrl + Enter
    ],
  }),
  computed: {
    ...sync("adminVersion", ["isShowDialogVersionForm", "dataForm", "params"]),
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate();
      if (!valueCheck) {
        return;
      }
      if (!this.dataForm.description) {
        this.$toast.error(
          this.$t("version.versionForm.notifications.error.errorDescription")
        );
        return;
      }
      const dataVersionUpdateResult = await this.$store.dispatch(
        `adminVersion/${!this.dataForm.id ? "create" : "update"}Version`,
        this.getTokenLogin()
      );
      if (dataVersionUpdateResult.status === false) {
        this.$toast.error(dataVersionUpdateResult.data.message);
        return;
      }
      this.$toast.success(
        `${!this.dataForm.id ? "Created" : "Updated"} Version Success`
      );
      setTimeout(() => {
        if (this.params.offset !== 1) {
          this.params.offset = 1;
        } else {
          this.$store.dispatch("adminVersion/fetchDataVersionList");
        }
        this.isShowDialogVersionForm = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .tiptap-vuetify-editor {
  margin-bottom: 1rem;
  .tiptap-vuetify-editor__content {
    max-height: 30rem;
    min-height: 10rem;
  }
}
</style>
