<template>
  <v-row class="px-4">
    <v-col cols="12" class="text-end">
      <v-btn color="success" @click="clickAddVersion" v-text="$t('user.userList.actions.add')" />
    </v-col>
  </v-row>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'HeaderForm',
  computed: {
    ...sync('adminVersion', [
      'isShowDialogVersionForm',
    ]),
  },
  methods: {
    clickAddVersion() {
      this.$store.dispatch('adminVersion/setDefaultFormDataVersion')
      this.isShowDialogVersionForm = true
    },
  },
}
</script>

<style scoped></style>
