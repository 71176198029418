<template>
  <div class="mt-5">
    <v-simple-table>
      <thead>
        <tr>
          <th
            class="primary--text font-weight-bold"
            v-text="'Title'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Date Updated'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Description'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Updated At'"
          />
          <th
            class="primary--text font-weight-bold text-center"
            v-text="$t('user.userList.table.actions')"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in dataItems"
          :key="index+'_dataItems'"
        >
          <td v-text="data.title" />
          <td v-text="data.dateUpdated" />
          <td>
            <div v-html="data.description" />
          </td>
          <td v-text="data.updatedAt" />

          <td class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="() => openFormEditVersion(data)">
                  <v-list-item-title v-text="$t('user.userList.actions.edit')" />
                </v-list-item>
                <v-divider />
                <v-list-item @click="() => clickDeleteVersion(data.id)">
                  <v-list-item-title v-text="$t('user.userList.actions.delete')" />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalItem, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // Vuex
  import { get, sync } from 'vuex-pathify'
  import { cloneDeep } from 'lodash'

  export default {
    name: 'TableVersion',
    computed: {
      ...get('adminVersion', [
        'dataItems',
        'totalItem',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
      ...sync('adminVersion', [
        'params',
        'isShowDialogVersionForm',
        'dataForm',
      ]),
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('adminVersion/fetchDataVersionList')
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('adminVersion/fetchDataVersionList')
          }
        },
        deep: true,
      },
    },
    async created () {
      if (this.params.offset !== 1) {
        this.params.offset = 1
      } else {
        await this.$store.dispatch('adminVersion/fetchDataVersionList')
      }
    },
    methods: {
      async openFormEditVersion (dataVersion) {
        this.dataForm = cloneDeep(dataVersion)
        this.isShowDialogVersionForm = true
      },
      async clickDeleteVersion(id) {
        const confirmed = await this.$swal({
          title: 'Item Delete Confirmation',
          text: 'Are you sure you want to delete this item?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#c32c39',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#9a9ea1',
          reverseButtons: true,
        })
        if (!confirmed.value) return false
        const resultAPI = await this.$store.dispatch('adminVersion/deleteVersion', {
          tokenLogin: this.getTokenLogin(),
          id,
        })
        if (!resultAPI.status) {
          this.$toast.error(resultAPI.data.message)
          return false
        }
        this.$toast.success('Delete Successfuly')
        this.showProgressCircular()
        setTimeout(() => {
          this.$store.dispatch('adminVersion/fetchDataVersionList')
        }, 1000)
      },
    },
  }
</script>
